
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FourZeroFour from "./components/FourZeroFour";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Home from "./containers/Home";
import Logged from "./containers/Logged";
import { fetchUser } from "./utils/fetchUser";
import ScaleLoader from "react-spinners/ScaleLoader";
import './App.css'

const App = () => {

  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = fetchUser()


    if (!user) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      if (location.pathname == '/sign-up') {
        navigate('/sign-up')
      } else {
        navigate('/login')
      }
    }

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])


  return (
    <div className="App">
      {loading ?
        <div className="loader">
          <ScaleLoader
            color={'#ccb757'}
            loading={loading}
            size={30}
          />
        </div>
        :
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Logged />} />
          <Route path="*" element={<FourZeroFour />} />
        </Routes>
      }
    </div>
    // <Home />
    // <Logged />
  );
}

export default App;
