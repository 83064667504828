import React from "react";

const Contact = () => {
  return (
    <div className="contact">
      <p className="contact-header">CONTACT US:</p>
      <div className="contact-container">
        <div className="c-name">
          <p>ADDRESS:</p>
          <p>PHONE:</p>
          <p>EMAIL:</p>
        </div>
        <div className="c-info">
          <p>Aventura, Florida</p>
          <p>+1 (920) 381-7380</p>
          <p>coralwealth.mt@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
