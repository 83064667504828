import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../client";
import { userQuery } from "../utils/data";
import "./style.css"

const Subscribe = () => {

    const navigate = useNavigate()

    const username = JSON.parse(localStorage.getItem('user')).username
    const query = userQuery(username)

    client.fetch(query)
        .then((data) => {
            localStorage.setItem('user', JSON.stringify(data[0]))
        })

    const user = JSON.parse(localStorage.getItem('user'))

    const useBal = (user.balance == undefined ? 0 : parseFloat(user.balance.replaceAll(',', '')));
    var balance = useBal


    const [buySuccess, setBuySuccess] = useState(null)
    const [complete, setComplete] = useState(null)
    const [active, setActive] = useState(false)
    const [basic, setBasic] = useState(200)
    const [gold, setGold] = useState(500)
    const [whale, setWhale] = useState(1000)
    const [whalePro, setWhalePro] = useState(5000)

    const setBasicPrice = (val) => {
        setBasic(val.target.value)
    }

    const setGoldPrice = (val) => {
        setGold(val.target.value)
    }

    const setWhalePrice = (val) => {
        setWhale(val.target.value)
    }

    const setWhaleProPrice = (val) => {
        setWhalePro(val.target.value)
    }


    const buyBasic = () => {
        if (basic < 200 || basic > 499) {
            setComplete(false)
            setBuySuccess(null)
            setTimeout(function () {
                setComplete(null)
            }, 5000)
        } else if (basic > balance) {
            setComplete(null)
            setBuySuccess(false)
            setTimeout(function () {
                setBuySuccess(null)
            }, 5000)
        } else if (user.plan != undefined) {
            setActive(true)
        } else {
            balance = balance - basic
            let repBalance = balance.toLocaleString()
            .patch(user.username).set({ balance: repBalance,  plan: 'BASIC PLAN', planAmount: basic.toLocaleString(), planDaysLeft: '3'}).commit().then(r => { console.log(r) })
            setComplete(null)
            setBuySuccess(true)
            setTimeout(function () {
                navigate('/user/dashboard')
            }, 3000)
        }
    }

    const buyGold = () => {
        if (gold < 500 || gold > 999) {
            setComplete(false)
            setBuySuccess(null)
            setTimeout(function () {
                setComplete(null)
            }, 5000)
        } else if (gold > balance) {
            setComplete(null)
            setBuySuccess(false)
            setTimeout(function () {
                setBuySuccess(null)
            }, 5000)
        } else if (user.plan != undefined) {
            console.log('already active')
            setActive(true)
        } else {
            console.log('successful')
            balance = balance - gold
            let repBalance = balance.toLocaleString()
            console.log(balance)
            client.patch(user.username).set({ balance: repBalance,  plan: 'GOLD PLAN', planAmount: gold.toLocaleString(), planDaysLeft: '10'}).commit()
            setComplete(null)
            setBuySuccess(true)
            setTimeout(function () {
                navigate('/user/dashboard')
            }, 3000)
        }
    }

    const buyWhale = () => {
        if (whale < 1000 || whale > 4999) {
            console.log('incomplete')
            setComplete(false)
            setBuySuccess(null)
            setTimeout(function () {
                setComplete(null)
            }, 5000)
        } else if (whale > balance) {
            console.log('insufficient')
            setComplete(null)
            setBuySuccess(false)
            setTimeout(function () {
                setBuySuccess(null)
            }, 5000)
        } else if (user.plan != undefined) {
            console.log('already active')
            setActive(true)
        } else {
            console.log('successful')
            balance = balance - whale
            let repBalance = balance.toLocaleString()
            console.log(balance)
            client.patch(user.username).set({ balance: repBalance,  plan: 'WHALE PLAN', planAmount: whale.toLocaleString(), planDaysLeft: '15'}).commit()
            setComplete(null)
            setBuySuccess(true)
            setTimeout(function () {
                navigate('/user/dashboard')
            }, 3000)
        }
    }

    const buyWhalePro = () => {
        if (whalePro < 5000) {
            console.log('incomplete')
            setComplete(false)
            setBuySuccess(null)
            setTimeout(function () {
                setComplete(null)
            }, 5000)
        } else if (whalePro > balance) {
            console.log('insufficient')
            setComplete(null)
            setBuySuccess(false)
            setTimeout(function () {
                setBuySuccess(null)
            }, 5000)
        } else if (user.plan != undefined) {
            console.log('already active')
            setActive(true)
        } else {
            console.log('successful')
            balance = balance - whalePro
            let repBalance = balance.toLocaleString()
            console.log(balance)
            client.patch(user.username).set({ balance: repBalance,  plan: 'WHALE-PRO PLAN', planAmount: whalePro.toLocaleString(), planDaysLeft: '25'}).commit()
            setComplete(null)
            setBuySuccess(true)
            setTimeout(function () {
                navigate('/user/dashboard')
            }, 3000)
        }
    }











    return (
        <div className='subscribe'>
            <p className="sub-header">AVAILABLE PACKAGES</p>

            <div className="plan-container">
                <p className="plan-name">Basic Plan (25%)</p>
                <p className="plan-price"><span>$</span>200</p>

                <div className="plan-desc">
                    <div className="pd-desc">
                        <p>Minimum</p>
                        <p>Maximum</p>
                        <p>Duration</p>
                    </div>
                    <div className="pd-value">
                        <p>200</p>
                        <p>499</p>
                        <p>7 days</p>
                    </div>
                </div>

                <div className="buy-plan">
                    <p>Amount to invest ($):</p>
                    <input id="basic" onChange={setBasicPrice} type="number" defaultValue={200} />
                    <p className={complete == false ? 'show-error failed' : 'collapse'}>Range $200 to $499!</p>
                    <p className={active == true ? 'show-error failed' : 'collapse'}>You already have an active plan!</p>
                    <p className={buySuccess == false ? 'show-error failed' : 'collapse'}>Insufficient funds!</p>
                    <p className={buySuccess == true ? 'show-error succeed' : 'collapse'}>Purchase Successful!</p>
                    <button className="btn" onClick={buyBasic}>BUY PLAN</button>
                </div>
            </div>

            <div className="plan-container">
                <p className="plan-name">Gold Plan (30%)</p>
                <p className="plan-price"><span>$</span>500</p>

                <div className="plan-desc">
                    <div className="pd-desc">
                        <p>Minimum</p>
                        <p>Maximum</p>
                        <p>Duration</p>
                    </div>
                    <div className="pd-value">
                        <p>500</p>
                        <p>999</p>
                        <p>7 days</p>
                    </div>
                </div>

                <div className="buy-plan">
                    <p>Amount to invest ($):</p>
                    <input id="gold" onChange={setGoldPrice} type="number" defaultValue={500} />
                    <p className={complete == false ? 'show-error failed' : 'collapse'}>Range $500 to $999!</p>
                    <p className={active == true ? 'show-error failed' : 'collapse'}>You already have an active plan!</p>
                    <p className={buySuccess == false ? 'show-error failed' : 'collapse'}>Insufficient funds!</p>
                    <p className={buySuccess == true ? 'show-error succeed' : 'collapse'}>Purchase Successful!</p>
                    <button onClick={buyGold}>BUY PLAN</button>
                </div>
            </div>

            <div className="plan-container">
                <p className="plan-name">Whale Plan (40%)</p>
                <p className="plan-price"><span>$</span>1,000</p>

                <div className="plan-desc">
                    <div className="pd-desc">
                        <p>Minimum</p>
                        <p>Maximum</p>
                        <p>Duration</p>
                    </div>
                    <div className="pd-value">
                        <p>1,000</p>
                        <p>4,999</p>
                        <p>7 days</p>
                    </div>
                </div>

                <div className="buy-plan">
                    <p>Amount to invest ($):</p>
                    <input id="whale" onChange={setWhalePrice} type="number" defaultValue={1000} />
                    <p className={complete == false ? 'show-error failed' : 'collapse'}>Range $1,000 to $4,999!</p>
                    <p className={active == true ? 'show-error failed' : 'collapse'}>You already have an active plan!</p>
                    <p className={buySuccess == false ? 'show-error failed' : 'collapse'}>Insufficient funds!</p>
                    <p className={buySuccess == true ? 'show-error succeed' : 'collapse'}>Purchase Successful!</p>
                    <button onClick={buyWhale}>BUY PLAN</button>
                </div>
            </div>

            <div className="plan-container">
                <p className="plan-name">Whale Pro Plan (50%)</p>
                <p className="plan-price"><span>$</span>5,000</p>

                <div className="plan-desc">
                    <div className="pd-desc">
                        <p>Minimum</p>
                        <p>Maximum</p>
                        <p>Duration</p>
                    </div>
                    <div className="pd-value">
                        <p>5,000</p>
                        <p>Unlimited</p>
                        <p>7 days</p>
                    </div>
                </div>

                <div className="buy-plan">
                    <p>Amount to invest ($):</p>
                    <input id="whalePro" onChange={setWhaleProPrice} type="number" defaultValue={5000} />
                    <p className={complete == false ? 'show-error failed' : 'collapse'}>Less than $5,000!</p>
                    <p className={active == true ? 'show-error failed' : 'collapse'}>You already have an active plan!</p>
                    <p className={buySuccess == false ? 'show-error failed' : 'collapse'}>Insufficient funds!</p>
                    <p className={buySuccess == true ? 'show-error succeed' : 'collapse'}>Purchase Successful!</p>
                    <button onClick={buyWhalePro}>BUY PLAN</button>
                </div>
            </div>
        </div>
    )
}

export default Subscribe
